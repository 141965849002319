import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Layout = ({ title, description, keywords, children }) => {
  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://example.com/peanut-firm.jpg" />
        <meta property="og:url" content="https://example.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {children}
    </div>
    </HelmetProvider>
  );
};

export default Layout;
