import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import '../Styling Files/Contact.css'
import Layout from './Layout';

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_q8vk8jx', 'template_0od56sz', form.current, {
        publicKey: 'qUE-qeov9Y_U9q4bX',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  return (
    <>
      <Layout
        title="Contact - Jai Bhagwati Traders(JBT)"
        description="Contact to Jai Bhagwati Traders(JBT) for any query. Feel free to connect anytime"
        keywords="jbt, jai bhagwati traders, jbtagroproducts, peanut, contact"
      >
        <div className='main'>
          <div className='contactContent'>
            <h1>Connect With Us</h1>
            <form className='formContent' ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <textarea className="textBox" rows="2" type="text" name="user_name" />
              <label>Email</label>
              <textarea className="textBox" type="email" name="user_email" />
              <label>Message</label>
              <textarea className="textBox" name="message" rows="5" />
              <button type="submit" className='submitButton'>Submit</button>
            </form>
            
            <div className='info'>
              <h1 style={{ marginBottom: 20 }}>Contact Us</h1>
              <h2>Email:</h2>
              <p className='desc'>jbt@gmail.com</p>
              <h2>Phone Number:</h2>
              <p className='desc'>+91 8429467123</p>
              <h2>Address:</h2>
              <p className='desc'>Kanpur, Uttar Pradesh, India</p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
