export const ItemStock = [
    {
        name: 'Peanut',
        description: 'We are committed to delivering the finest quality peanuts to our valued customers. Our rigorous selection process ensures that only the best peanuts make it to your table, offering unparalleled taste and nutrition.',
        image: require("../Component Images/Peanut.jpg"),
        productImage: require("../Component Images/PeanutProduct.jpg"),
        Price: '₹61/Kg'
    },

    {
        name: 'Barley',
        description: 'We are dedicated to delivering the highest quality barley to meet your needs. Our commitment to excellence ensures that every batch of barley we provide meets the strictest standards of purity and freshness.',
        image: require("../Component Images/Barley.jpeg"),
        productImage: require("../Component Images/BarleyProduct.jpg"),
        Price: '₹21/Kg'
    },

    {
        name: 'Edible Oil',
        description: 'We are committed to delivering the highest quality edible oil available on the market. Our products are meticulously sourced and processed to ensure exceptional purity, flavor, and nutritional value.',
        image: require("../Component Images/Edible Oil.jpeg"),
        productImage: require("../Component Images/Edible Oil Product.jpg"),
        Price: '₹122/Kg'
    },

    {
        name: 'Pea',
        description: 'We are committed to delivering the highest quality peas to our valued customers. Our stringent quality control processes ensure that each pea meets our exceptional standards for freshness, taste, and nutritional value.',
        image: require("../Component Images/Pea.jpg"),
        productImage: require("../Component Images/Pea Product.jpg"),
        Price: '₹45/Kg'
    },

    {
        name: 'Roasted Gram',
        description: 'We are committed to delivering the highest quality roasted gram to our customers. Our meticulous selection process ensures that only the finest grams are chosen for roasting, guaranteeing exceptional taste and superior nutritional value.',
        image: require("../Component Images/Roasted Gram.jpeg"),
        productImage: require("../Component Images/RoastedGram Product.jpg"),
        Price: '₹290/Kg'
    },

]