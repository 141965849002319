import './App.css';
import Navbar from './Peanut Components/JS Files/Navbar';
import Home from './Peanut Components/JS Files/Home';
import About from './Peanut Components/JS Files/About';
import Contact from './Peanut Components/JS Files/Contact';
import SingleItem from './Peanut Components/JS Files/SingleItem';
import { ItemStock } from './Peanut Components/JS Files/ItemStock';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route exact path = '/' element={<Home/>}></Route>
        <Route exact path = '/about' element={<About/>}></Route>
        <Route exact path = '/contact' element={<Contact/>}></Route>
        {ItemStock.map((element) => (
          <Route key={element.name} exact path = {`/singleitem/${element.name}`} element={<SingleItem/>}></Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
