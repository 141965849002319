import React from 'react'
import { useLocation } from 'react-router-dom';
import '../Styling Files/SingleItem.css'
import Layout from './Layout';

export default function SingleItem() {
  const location = useLocation();
  let {name, image, productImage, description} = location.state;
  return (
    <>
    <Layout
      title="SingleItem - Jai Bhagwati Traders(JBT)"
      description="These are the products offered by Jai Bhagwati Traders(JBT) along with a contact option to buy the item."
      keywords="jbtagroproducts, jbt, jai bhagwati traders, peanut, groundnut, barley, roasted gram"
    >
    <div className='main'>
      <div className='SingleItemMain'>
        <img className='singleImage' src={productImage} alt='...'/>
        <div className='mainInfo'>
          <p className='title'>{name}</p>
          <p className='description'>{description}</p>
        </div>
        <a className='contactButton' href="tel:+91-8429467123">Contact Us</a>
      </div>
    </div>
    </Layout>
    </>
  )
}
