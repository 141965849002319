import React from 'react'
import { ItemStock } from './ItemStock'
import {useNavigate} from 'react-router-dom'
import '../Styling Files/Home.css'
import Layout from './Layout'

export default function Home() {
  const navigate = useNavigate();
  return (
    <>
    <Layout
      title="Home - Jai Bhagwati Traders(JBT)"
      description="Products - Peanut, Groundnut, Barley production"
      keywords="jbtagroproducts, jbt, jai bhagwati traders, peanut, groundnut, barley, roasted gram"
    >
    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {ItemStock.map((element, index) => {
          return <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={element.name}>
          <img src={element.image} className="d-block w-100" alt="..."/>
        </div>
        })}
      </div>
    </div>
    <div className='homeContent'>
      <div className='homeIntro'>
        <p>
        Jai Bhagwati Traders(JBT) is one of the leading wholesalers in the agro-products industry, specializing in groundnuts and a diverse range of other agricultural commodities. With a legacy spanning 25 years, we boost expertise and reliability in the market. 
        </p>
        <p>
        With a focus on delivering premium quality at competitive prices, we cater not only to the domestic market but also export our goods to other countries.
        </p>
      </div>
      <h1 className='homeHeading'>Updating Prices</h1>
      <div className='updatingPrices'>
      {ItemStock.map((element) => {
        return <div className='singlePrice' key={element.name}>
          <h3>{element.name}</h3>
          <h4>{element.Price}</h4>
      </div>
      })}
      </div>
      <h1 className='homeHeading'>Our Products</h1>
      {ItemStock.map((element) => {
        return <div className="card" key={element.name}>
        <img src={element.productImage} className="card-img-top" alt="..."/>
        <div className="card-body">
          <h5 className="card-title">{element.name}</h5>
          <button onClick={() => {navigate(`/singleitem/${element.name}`,{
            state: {
              name: element.name,
              image: element.image,
              description: element.description,
              productImage: element.productImage
          }})}} className="btn btn-primary">Know More</button>
        </div>
      </div>
      })}
    </div>
    </Layout>
    </>
  )
}

