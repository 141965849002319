import React from 'react'
import '../Styling Files/About.css'
import Layout from './Layout'

export default function About() {
  const fssaiImage = require("../Component Images/FSSAI Image.jpg")
  const recogImage = require("../Component Images/Recognition Image.jpg")
  const regisImage = require("../Component Images/Registration Image.jpg")
  return (
    <>
    <Layout
      title="About - Jai Bhagwati Traders(JBT)"
      description="Leading wholesaler in the agro-products industry, specializing in groundnuts"
      keywords="jbtagroproducts, jbt, jai bhagwati traders, jbtCompany, production, peanut"
    >
    <div className='main'>
      <div className='content'>
        <p className='paras'>Introducing Jai Bhagwati Traders(JBT), a leading wholesaler in the agro-products industry, specializing in groundnuts and a diverse range of other agricultural commodities. With a legacy spanning 25 years, we boost expertise and reliability in the market. Certified by FSSAI and recognized for our quality standards, we ensure excellence in every product we offer. Under the leadership of Mr. Durga Prasad Gupta, we have expanded our offerings to include barley, edible oil, peas, roasted gram, and more. With a focus on delivering premium quality at competitive prices, we cater not only to the domestic market but also export their goods to countries like Nepal and Bangladesh. With nearly a decade of experience in manufacturing, Jai Bhagwati Traders stands as a trusted name committed to customer satisfaction and industry excellence.</p>

        <img className="certificates" src={fssaiImage} alt=""/>
        <p className='paras'>Having an FSSAI (Food Safety and Standards Authority of India) certificate is crucial for businesses operating in the agro industry. It signifies compliance with food safety and quality standards set by the Indian government, ensuring that the products meet specified regulations regarding hygiene, safety, and quality.</p>

        <img className="certificates" src={recogImage} alt=""/>
        <p className='paras'>Recognition certificates play a vital role in the agro-industry, serving as tangible acknowledgments of excellence, adherence to standards, and contributions to sustainable practices. </p>

        <img className="certificates" src={regisImage} alt=""/>
        <p className='paras'>Our registration certificate in the agro-industry is akin to a license or permit granted by regulatory authorities to operate within the sector. It serves as formal acknowledgment that a business or entity meets certain standards and requirements set forth by governmental or industry bodies.</p>
      </div>
    </div>
    </Layout>
    </>
  )
}
